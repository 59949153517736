import React from "react"
import { Layout } from "../components/Layout"
import { graphql, Link } from "gatsby"
import { Section } from "../components/Section"
import { Container } from "../components/Container"
import { Tags } from "../components/Tags"
import RehypeReact from "rehype-react"
import { BreadCrumbs } from "../components/Breadscrumbs"
import { HomeCrumb } from "../pages"
import GatsbyImage from "gatsby-image"

const renderAst = new RehypeReact({
  createElement: React.createElement,
  components: { "tags": Tags }
}).Compiler

export default ({ data: { project: { htmlAst, excerpt, frontmatter: { title, company, start, end, role, featuredImage, skills, tech }, fields: { name } } } }) => (
  <Layout seo={{
    title,
    path: `/projects/${name}`,
    image: featuredImage.childImageSharp.fluid.src,
    description: excerpt
  }}>
    <Container>
      <BreadCrumbs crumbs={[
        HomeCrumb,
        {
          name: "Projects",
          page: "/#projects",
          icon: "fa-boxes"
        },
        {
          name: title,
          page: `/projects/${name}`,
          icon: "fa-archive"
        }
      ]}/>
    </Container>
    <Section id="project">
      <div className="centered">
        <h1>{title}</h1>
        <span className="sub">
        {company} - {start && `${start} to`} {end} - {role}
        </span>
        <Tags value={skills}/>
      </div>
      <GatsbyImage className="featured" {...featuredImage.childImageSharp}/>
      <Container width="tight">
        <div className="article-content">
          {renderAst(htmlAst)}
        </div>
      </Container>
    </Section>
    {tech && (
      <Section id="tags">
        <Container width="tight">
          <div className="centered">
            <Tags value={tech}/>
          </div>
        </Container>
      </Section>
    )}
    {/*<Section id="more-info">*/}
    {/*  <Container width="tight">*/}
    {/*    <h2>Learn More</h2>*/}
    {/*    <p>*/}
    {/*      Do you want to learn more about this project? Get in touch and I will gladly tell you more over a cup of*/}
    {/*      coffee.*/}
    {/*    </p>*/}
    {/*    <Link className="cta" to="/#contact">Get in touch!</Link>*/}
    {/*  </Container>*/}
    {/*</Section>*/}
  </Layout>
);

export const pageQuery = graphql`
query ($name: String) {
  project: markdownRemark(fields: {sourceName: {eq: "projects"}, name: {eq: $name}}) {
    frontmatter {
      company
      end
      featuredImage {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      role
      skills
      start
      title
      tech
    }
    fields {
      name
    }
    htmlAst
    excerpt(format: PLAIN)
  }
}
`

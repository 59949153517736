import React from "react"
import { Link } from "gatsby"

export interface Crumb {
  name: string;
  page: string;
  icon: string;
}

export interface Props {
  crumbs: Crumb[]
}

export const BreadCrumbs: React.FC<Props> = ({ crumbs }) => (
  <div className="breadcrumbs">
    {crumbs.map(({ name, page, icon }, i) => (
      <Link key={i} to={page}>
        <i className={`fas ${icon}`}/> {name}
      </Link>
    ))}
  </div>
)

import React from "react"

export interface Props {
  value: string[] | string;
}

export const Tags: React.FC<Props> = ({ value }) => (
  <div className="tags">
    {(typeof value === "string" ? value.split(",").map(v => v.trim()) : value)
      .map((v, i) => (
        <span key={i}>
      {v}
      </span>
      ))}
  </div>
)
